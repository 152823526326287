(function() {
	'use strict';

	angular
		.module('app', [
			'ui.router'
		]);

})();

// ResponseController
(function() {
	'use strict';

	angular
		.module('app')
		.controller('ResponseController', ResponseController);

	function ResponseController(LoadingFactory) {

        LoadingFactory.hideLoading();

	}
})();

(function () {
    'use strict';

    angular
        .module('app')
        .factory('ProductsFactory', ProductsFactory);

    function ProductsFactory($http, $q, $state, AuthFactory, endpoint, $window) {

        var self = {};
        self.products = [];

        self.getProducts = function () {

            var deferred = $q.defer();
            var url = $window.location.hostname == 'localhost' ? 'app/modules/products/many_fibre_products.json' : endpoint + 'fibre/auto-map';
            var token = AuthFactory.getToken();

            $http({
                url: url,
                method: 'POST',
                cache: false,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(function (response) {

                switch (response.data.action) {

                    // has multiple products
                    case 'select':
                        self.products = response.data.data_products;

                        // resolve the promise
                        deferred.resolve(response.data);
                        break;

                    // has one product, mapping complete
                    case 'mapped':
                        $state.go('success');
                        break;

                    // has no products, redirect to error state
                    case 'no_products':
                        $state.go('no-products');
                        break;

                    case 'error':
                        $state.go('error');
                        break;
                }

            }, function (message) {

                $state.go('error');

                // reject the promise
                deferred.reject(message);

            });

            return deferred.promise;

        };

        self.mapProduct = function (id) {
            var url = endpoint + 'fibre/map/' + id;
            var token = AuthFactory.getToken();

            $http({
                url: url,
                method: 'POST',
                cache: false,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(function (response) {

                switch (response.data.action) {

                    // has one product, mapping complete
                    case 'mapped':
                        $state.go('success');
                        break;

                    // has no products, redirect to error state
                    case 'no_products':
                        $state.go('no-products');
                        break;

                    case 'error':
                        $state.go('error');
                        break;
                }

            }, function (message) {

                // jump to error
                $state.go('error');

            });

        };

        //magic map the client as they hit the login page
        self.autoMapDetectProduct = function () {
            var url = endpoint + 'fibre/auto-map-detect';

            return $http({
                url: url,
                method: 'POST',
                cache: false
            }).then(function (response) {
                switch (response.data.action) {
                    // magic map
                    case 'mapped':
                        $state.go('success');
                        break;
                }
            }, function (err) {

            });

        };

        return self;
    }

}());

(function() {
	'use strict';

	angular
		.module('app')
		.controller('ProductsController', ProductsController);

	function ProductsController($state, ProductsFactory, LoadingFactory) {

		var vm = this;
		vm.ProductsFactory = ProductsFactory;
        LoadingFactory.hideLoading();

		vm.mapProduct = function(productId) {

            // show loader
            LoadingFactory.showLoading();

			// submit to API
			ProductsFactory.mapProduct(productId);

		};

	}
})();

(function() {
    'use strict';

    angular
        .module('app')
        .factory('LoadingFactory', LoadingFactory);

    function LoadingFactory() {

        var self = {};
        self.loading = false;

        self.showLoading = function() {
            self.loading = true;
        };

        self.hideLoading = function() {
            self.loading = false;
        };

        return self;
    }

}());

(function() {
    'use strict';

    angular
        .module('app')
        .controller('LoadingController', LoadingController);

    function LoadingController(LoadingFactory) {

        var vm = this;
        vm.LoadingFactory = LoadingFactory;

    }
})();

(function() {
	'use strict';

	angular
		.module('app')
		.factory('AuthFactory', AuthFactory);

	function AuthFactory($http, $q, $window, endpoint) {

		var self = {};

		// save token to local storage
		self.saveToken = function(token) {
			$window.localStorage['walled_token'] = token;
		};

		// get token from local storage
		self.getToken = function() {
			return $window.localStorage['walled_token'] || false;
		};

		self.doLogin = function(credentials) {

			var deferred = $q.defer();
			var creds = {
				_username: credentials.email,
				_password: credentials.password
			};

			$http({
				url: endpoint + 'jwt_login_check',
				method: 'POST',
				data: creds
			}).then(function(response) {

				deferred.resolve(response.data);

			}, function(error) {

				deferred.reject(error);

			});

			return deferred.promise;

		};

		// self.urlEncode = function (data) {
		//
		// 	// If this is not an object, defer to native stringification.
		// 	if (!lodash.isObject(data)) {
		// 		return ((data == null) ? "" : data.toString());
		// 	}
		//
		// 	var buffer = [];
		//
		// 	// Serialize each key in the object.
		// 	for (var name in data) {
		//
		// 		if (!data.hasOwnProperty(name)) {
		// 			continue;
		// 		}
		//
		// 		var value = data[name];
		//
		// 		// handle multiple
		// 		if (lodash.isArray(value)) {
		//
		// 			for (var i = 0; i < value.length; i++) {
		// 				buffer.push(encodeURIComponent(name + '[]') + "=" + encodeURIComponent((value[i] == null) ? "" : value[i])); // hacky as fuck bit it works...
		// 			}
		//
		// 		} else {
		// 			buffer.push(encodeURIComponent(name) + "=" + encodeURIComponent((value == null) ? "" : value));
		// 		}
		//
		// 	}
		//
		// 	// Serialize the buffer and clean it up for transportation.
		// 	return buffer.join("&").replace(/%20/g, "+");
		// };

		return self;

	}
})();

(function () {
    'use strict';

    angular
        .module('app')
        .controller('AuthController', AuthController);

    function AuthController(AuthFactory, $state, LoadingFactory, ProductsFactory) {


        var vm = this;
        vm.error = false;
        vm.credentials = {
            email: '',
            password: ''
        };

        LoadingFactory.showLoading();
        ProductsFactory.autoMapDetectProduct().then(function (data) {
            LoadingFactory.hideLoading();
        });


        vm.login = function () {

            // remove the error
            vm.error = '';

            // check for email address
            if (vm.credentials.email == '') {
                vm.error = 'Please enter your email address';
                return false;
            }

            // check for password
            if (vm.credentials.password.length < 1) {
                vm.error = 'Please enter your password';
                return false;
            }

            // show loader
            LoadingFactory.showLoading();

            // try login
            AuthFactory.doLogin(vm.credentials).then(function (response) {

                // check response for token
                if (!response.token && response.code == 401) {

                    // show response error message
                    vm.error = 'Invalid credentials, please try again';
                    LoadingFactory.hideLoading();

                } else if (!response.token) {

                    // show generic error message
                    vm.error = 'Unable to login, please try again';
                    LoadingFactory.hideLoading();

                } else {

                    AuthFactory.saveToken(response.token);
                    $state.go('products');

                }


            }, function () {

                // show error message
                LoadingFactory.hideLoading();
                vm.error = 'Unable to login, please try again';

            });

        };

    }
})();

(function() {
	'use strict';

	angular
		.module('app')
		.run(runBlock);

	function runBlock($log) {


		$log.debug('runBlock end');
	}

})();

(function() {
	'use strict';

	angular
		.module('app')
		.config(routerConfig);

	/** @ngInject */
	function routerConfig($stateProvider, $urlRouterProvider) {
		$stateProvider
			.state('auth', {
				url: '/',
				templateUrl: 'app/modules/auth/auth.html',
				controller: 'AuthController',
				controllerAs: 'auth'
			})
			.state('products', {
				url: '/products',
				templateUrl: 'app/modules/products/products.html',
				controller: 'ProductsController',
				controllerAs: 'products',
				resolve: {
					products: ['$stateParams', 'ProductsFactory',
						function($stateParams, ProductsFactory) {
							return ProductsFactory.getProducts();
						}]
				}
			})
			.state('success', {
				url: '/success',
				templateUrl: 'app/modules/responses/success.html',
				controller: 'ResponseController',
				controllerAs: 'response'
			})
			.state('no-products', {
				url: '/no-products',
				templateUrl: 'app/modules/responses/no-products.html',
				controller: 'ResponseController',
				controllerAs: 'response'
			})
			.state('error', {
				url: '/error',
				templateUrl: 'app/modules/responses/error.html',
				controller: 'ResponseController',
				controllerAs: 'response'
			});

		$urlRouterProvider.otherwise('/');
	}

})();

(function() {
	'use strict';

	angular
		.module('app')
		.constant('endpoint', 'https://clientzone.afrihost.com/en/api/');

})();

(function() {
	'use strict';

	angular
		.module('app')
		.config(config);

	function config($logProvider) {

		// Enable log
		$logProvider.debugEnabled(false);

	}

})();

angular.module("app").run(["$templateCache", function($templateCache) {$templateCache.put("app/modules/auth/auth.html","<div class=\"intro-container small\"><h1>Activate Your Fibre Package</h1><p>To activate your Fibre package, please log into your ClientZone account below.</p></div><form class=\"login\" ng-submit=\"auth.login()\" autocomplete=\"off\"><label class=\"input-container\"><input type=\"email\" ng-model=\"auth.credentials.email\" placeholder=\"email address\" autocomplete=\"off\"> <span class=\"fi fi-email\"></span></label> <label class=\"input-container\"><input type=\"password\" ng-model=\"auth.credentials.password\" placeholder=\"password\" autocomplete=\"off\"> <span class=\"fi fi-padlock\"></span></label> <button class=\"btn-main\" type=\"submit\">Login</button><p class=\"error\" ng-class=\"{\'show\': auth.error}\">{{ auth.error }}</p></form>");
$templateCache.put("app/modules/products/products.html","<div class=\"intro-container large\"><h1>Select a Package To Use</h1><p>Please select the Fibre package you would like to use<br>for your current connection from the list below.</p></div><div class=\"products\"><dl class=\"product fi fi-arrow-right\" ng-repeat=\"product in products.ProductsFactory.products\" ng-click=\"products.mapProduct(product.id)\"><dt>{{ product.location.name }}</dt><dd>{{ product.solution_data.name }} {{ (product.solution_data.group_name).replace(\' Uncapped Fibre\', \'\') }} {{ product.solution_line.group_name }}</dd></dl></div>");
$templateCache.put("app/modules/responses/error.html","<div class=\"error-message\"><span class=\"icon fi fi-cross\"></span><h1>Whoops!</h1><p>Something went wrong. Please contact <a href=\"mailto:support@afrihost.com\">support@afrihost.com</a> for further assistance.</p></div>");
$templateCache.put("app/modules/responses/no-products.html","<div class=\"error-message\"><span class=\"icon fi fi-cross\"></span><h1>No Packages Found</h1><p>Looks like you don\'t have any Fibre packages yet. Hit the button below to signup for a new Fibre package now.</p><a href=\"https://clientzone.afrihost.com/order/#/fibre/coverage\" class=\"btn-main\">Signup Now</a></div>");
$templateCache.put("app/modules/responses/success.html","<div class=\"success-message\"><span class=\"icon fi fi-check\"></span><h1>You\'re Good To Go!</h1><p>You should be able to connect to the internet in a few minutes. If you have any queries, please contact <a href=\"mailto:support@afrihost.com\">support@afrihost.com</a>.</p></div>");}]);